import {Context, createContext} from 'react';

import InterfaceStore from "./InterfaceStore";
import I18nStore from "./i18nStore";
import OrganizationStore from "./OrganizationStore";
import UserStore from './UserStore'
import ModalStore from "./ModalStore";
import StyleStore from './StyleStore';
import GridStore from './GridStore';
import SidebarStore from './SidebarStore';
import {SchoolFeedStore} from "./SchoolFeedStore";

// load this file only once!

export const interfaceStore = new InterfaceStore();
export const userStore = new UserStore();
export const organizationStore = new OrganizationStore(userStore);
export const modalStore = new ModalStore();
export const styleStore = new StyleStore(interfaceStore, organizationStore);
export const gridStore = new GridStore();
export const i18nStore = new I18nStore(organizationStore);
export const sidebarStore = new SidebarStore(interfaceStore);
export const schoolFeedStore = new SchoolFeedStore();

const allStores = {
    interfaceStore,
    organizationStore,
    i18nStore,
    modalStore,
    userStore,
    styleStore,
    gridStore,
    sidebarStore,
    schoolFeedStore,
};

export default allStores;

export const StoreContext = createContext(allStores) as Context<any>;

export function loadStores() {
    const interfaceStore = new InterfaceStore();
    const userStore = new UserStore();
    const organizationStore = new OrganizationStore(userStore);
    const modalStore = new ModalStore();
    const styleStore = new StyleStore(interfaceStore, organizationStore);
    const gridStore = new GridStore();
    const i18nStore = new I18nStore(organizationStore);
    const sidebarStore = new SidebarStore(interfaceStore);
    const schoolFeedStore = new SchoolFeedStore();

    return {
        interfaceStore,
        organizationStore,
        modalStore,
        userStore,
        styleStore,
        gridStore,
        i18nStore,
        sidebarStore,
        schoolFeedStore,
    }
}
