import {action, makeObservable, observable, reaction} from "mobx";
import URI from "urijs";
import SafeRouter from "../components/utilities/SafeRouter";
import {ExecutionEnvironment} from "./InterfaceStore";

type ISidebar = {
    view: string,
    handleSave?: (contentItem: ContentItem, organizationId: string) => void,
    handleDelete?: (contentItem: ContentItem) => void,
    [anything: string]: any,
};

export default class SidebarStore {
    sidebar: ISidebar | null = null;
    historyStack: ISidebar[] = [];

    constructor(interfaceStore) {
        if (ExecutionEnvironment.canUseDOM) {
            reaction(() => interfaceStore.currentFullUrl, () => {
                const currentFullUrlParts = URI(interfaceStore.currentFullUrl);

                if (currentFullUrlParts.hasQuery('spview')) {
                    const query = currentFullUrlParts.query(true);

                    if (query.spview !== "SchoolFeedPost") {
                        // we need additional context (handleSave/handleDelete) for SchoolFeedPost to work, so this
                        // view can't be navigated to via URL
                        this.sidebar = {...query, view: query.spview};
                    }
                }
            }, {fireImmediately: true})
        }

        makeObservable(this, {
            sidebar: observable,
            setSidebar: action,
            historyStack: observable,
            close: action,
            back: action,
        })
    }

    setQueriesToOnly = async (...queries) => {
        await SafeRouter.setQueries(["sbtype"], ["id"], ["spview"], ["accounts"], ["accessToken"], ...queries);
    }

    setSidebar = async (obj: ISidebar) => {
        // add any query parameters that can be added, this is largely here for backwards compatibility
        await this.setQueriesToOnly(
            ...Object.entries(obj).filter(([k, v]) => (typeof v === "string" || typeof v === "number") && k !== "view"),
            ["spview", obj.view]
        );

        this.sidebar = obj;

        if (JSON.stringify(obj) !== JSON.stringify(this.historyStack[this.historyStack.length - 1])) {
            this.historyStack.push(obj);
        }
    }

    close = async () => {
        // remove all possible sidebar related query params, largely here for backwards compatibility
        await this.setQueriesToOnly();
        this.sidebar = null;
        this.historyStack = [];
    }

    back = async () => {
        const nextSidebar = this.historyStack[this.historyStack.length - 2];
        await this.setSidebar(nextSidebar);
        this.historyStack.splice(this.historyStack.length - 2, 2);
    }
}
