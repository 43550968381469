/*
For now, this file needs to be es5 because it's imported inside next.config.js, which is not run through Babel.
If that changes, then this file can be upgraded to es6.
 */
const sb_production = require('./production/schoolblocks_config.json');
const sb_development = require('./development/schoolblocks_config.json');
const sb_beta = require('./beta/schoolblocks_config.json');
const sf_development = require("./development/schoolfeed_config.json");
const sf_beta = require("./beta/schoolfeed_config.json");
const sf_production = require("./production/schoolfeed_config.json");

const allConfigs = {
    'dev': {
        'sb': sb_development,
        'sf': sf_development,
    },
    'beta': {
        'sb': sb_beta,
        'sf': sf_beta,
    },
    'prod': {
        'sb': sb_production,
        'sf': sf_production,
    },
};

let appEnv = 'dev';

// This object should be consistent with the NextJS configuration object and is defined as a type in TypeScript.
const appConfig = {
    serverRuntimeConfig: {},
};

appEnv = process.env.NEXT_PUBLIC_APP_ENV || appEnv;

switch (process.env.NEXT_PUBLIC_APP_CTX) {
    case "schoolfeed":
        appConfig.publicRuntimeConfig = allConfigs[appEnv].sf;
        break;
    case "schoolblocks":
        appConfig.publicRuntimeConfig = allConfigs[appEnv].sb;
        break;
    default:
        appConfig.publicRuntimeConfig = allConfigs[appEnv].sb;
}

module.exports = appConfig;
